
import {Component, Vue} from 'vue-property-decorator';
import AppStore from '@/components/AppStore';
import AppTopBar from '@/components/UI/AppTopBar.vue';
import MenuTop from '@/components/MenuTop.vue';
import RnisEmulationTable from '@/components/rnis/emulation/RnisEmulationTable.vue';
import 'mapbox-gl/dist/mapbox-gl.css';


@Component({
  components: {RnisEmulationTable, MenuTop, AppTopBar, Map}
})
export default class RnisEmulationView extends Vue {

  containerWidth = AppStore.containerWidth;


  mounted() {
    AppStore.onContainerWidth(v => this.containerWidth = v);
    AppStore.setDisplayModeTable();

    //
    // mapboxgl.accessToken = 'pk.eyJ1IjoiZnJvbnRnb2xkIiwiYSI6ImNseHQzZTNxdTAxZGsyaXNjYXI5amVweHEifQ.Dsb-UUPRX4UnK6Gs5S6Wvg';
    //
    // const map = new mapboxgl.Map({
    //   container: 'map-container',
    //   style: 'mapbox://styles/mapbox/streets-v12',
    //   center: [-122.483696, 37.833818],
    //   zoom: 15,
    // });
    //
    // const language = new MapboxLanguage();
    // map.addControl(language);
    //
    //
    // map.on('load', () => {
    //
    //   Http.post('/rnis/emulation/coordinates', {id: 608}).then((response: any) => {
    //
    //
    //     map.addSource('route', {
    //       'type': 'geojson',
    //       'data': {
    //         'type': 'Feature',
    //         'properties': {},
    //         'geometry': {
    //           'type': 'LineString',
    //           'coordinates':
    //             response
    //
    //         }
    //       }
    //     });
    //
    //     map.addLayer({
    //       'id': 'route',
    //       'type': 'line',
    //       'source': 'route',
    //       'layout': {
    //         'line-join': 'round',
    //         'line-cap': 'round'
    //       },
    //       'paint': {
    //         'line-color': '#3bab3f',
    //         'line-width': 6
    //       }
    //     });
    //
    //     console.log(response);
    //
    //     map.flyTo({
    //       center: [response[0][0], response[0][1]],
    //       essential: true // this animation is considered essential with respect to prefers-reduced-motion
    //     });
    //
    //   }).finally(() => {
    //
    //
    //   }).catch((HttpError) => {
    //     Http.systemError(HttpError.error);
    //   });
    //
    //
    // });

  }

  created() {


  }
}
