
import {Component, Vue} from 'vue-property-decorator';
import Http from '@/Http';
import AppStore from '@/components/AppStore';
import DiagnosticCardStatusChip from '@/components/dcs/DiagnosticCardStatusChip.vue';
import ExportExcel from '@/components/ExportExcel.vue';
import DcsExportExcel from '@/components/dcs/DcsExportExcel.vue';


@Component({
  components: {DcsExportExcel, ExportExcel, DiagnosticCardStatusChip}
})
export default class DcsTable extends Vue {

  loading = true;
  items: any[] = [];

  backlight = '';
  regNumbers: string[] = AppStore.regNumbers;

  filterData = {
    regNumber: '',
    status: '',
  };


  openMap(id: number) {

    this.loading = true;
    Http.post('/rnis/emulation/mapUrl', {id}).then((response: any) => {
      window.open(response.url, '_blank');
    }).finally(() => {
      this.loading = false;
      AppStore.hideLoader();
    }).catch((HttpError) => {
      switch (HttpError.error) {
        default:
          Http.systemError(HttpError.error);
      }
    });
  }

  load() {
    AppStore.showLoader();
    this.loading = true;
    Http.post('/rnis/emulation', this.filterData).then((response: any) => {
      this.items = response;
    }).finally(() => {
      this.loading = false;
      AppStore.hideLoader();
    }).catch((HttpError) => {
      switch (HttpError.error) {
        default:
          Http.systemError(HttpError.error);
      }
    });
  }

  init() {
    this.load();
  }

  getStatusColor(status: string) {
    switch (status) {
      case'COMPLETED':
        return 'green';
      case'CANCELED':
        return 'grey';
      case'IN_WORK':
        return 'blue';
      case'CALCULATION':
        return 'orange';
      default:
        return status;

    }
  }

  getStatusLabel(status: string) {

    switch (status) {
      case'COMPLETED':
        return 'ЗАВЕРШЕН';
      case'CANCELED':
        return 'ОТМЕНЕН';
      case'IN_WORK':
        return 'В РАБОТЕ';
      case'CALCULATION':
        return 'ОЖИДАНИЕ ЗАПУСКА';
      default:
        return status;

    }
  }

  mounted() {

    if (document.location.hash) {
      const rn = document.location.hash.substr(1);
      this.filterData.regNumber = decodeURI(rn);
    }
    this.init();

  }
}
